import React from "react";
import classes from "./Styles/Section1styles.module.css";
const Section1 = () => {
  return (
    <section className={classes.section}>
      <div className={classes.details}>
        <div className={classes.name}>
          <h1>Driving Connections, Powering Transactions</h1>
        </div>

        <div className={classes.stack}>
          <p>
            At Sprinterwave, we’re more than just a ride—we’re your partner in
            seamless travel, effortless bill payments, and unforgettable event
            bookings. Whether you’re moving people or managing tasks, we’re here
            to connect you to what matters most, faster and smarter than ever
            before.
          </p>

          <div className={classes.download_}>
            <img src="/images/appstore_.webp" alt="" />
            <img src="/images/playstore_.webp" alt="" />
          </div>
        </div>
      </div>

      {/* <div className={classes.left_image}>
        <img
          src="https://advanture.icu/bden/wp-content/uploads/sites/18/2023/04/MobileApp02.png"
          alt=""
        />
      </div> */}
    </section>
  );
};

export default Section1;
