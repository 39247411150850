import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { usePostRequestMutation } from "../../store/services/users";
import classes from "./Styles/Section1styles.module.css";
const RiderSection1 = () => {
  const [Register, {}] = usePostRequestMutation();
  const navigate = useNavigate();
  const [code, setCode] = useState<string>("");

  let [enteredData, setEnteredData] = useState({
    email: "",
    password: "",
  });

  const [showForm, setShowForn] = useState<boolean>(false);
  const [showVerifyForm, setShowVerifyForm] = useState<boolean>(false);

  const toggleLogin = () => {
    setShowForn(!showForm);
  };
  const toggleVerificationForm = () => {
    setShowVerifyForm(!showVerifyForm);
  };
  const onChangeHandler = (element: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [element.target.name]: element.target.value,
      };
    });
  };

  useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    Register({
      url: "/login",
      body: { ...enteredData },
    })
      .unwrap()
      .then((data) => {
        onRequestCodeHandler();
        toggleVerificationForm();
      })
      .catch((error) => {
        console.log("Error", error);
        alert(error.data.error);
      });
  };

  // Re generate code
  const onRequestCodeHandler = () => {
    Register({
      url: "/generate-confirmation-code",
      body: {
        emailType: "verify-email",
        email: enteredData.email,
      },
    })
      .unwrap()
      .then((data) => {
        alert("Code sent");
      })
      .catch((error) => {
        console.log("Error", error.data.message);
        alert(error.data.message);
      });
  };

  const onVerifyhandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    Register({
      url: "/acccount-verification-verify-code",
      body: {
        email: enteredData.email,
        code: code,
      },
    })
      .unwrap()
      .then((data) => {
        localStorage.setItem("token", data.user.token);
        navigate("/rider-application");
      })
      .catch((error) => {
        console.log("Error", error.data.message);
        alert(error.data.message);
      });
  };
  return (
    <>
      <section className={classes.section + " " + classes.noBg}>
        <div className={classes.details}>
          <div className={classes.name}>
            <h1 style={{textAlign: "left", fontSize: 30, marginBottom: 10}}>Join the Sprinterwave Family: Ride or Drive with Us!"</h1>
          </div>

          <div className={classes.stack2}>
            <p>
              Want to be part of the Sprinterwave team? Whether you’re applying
              as a rider or a driver, we’re excited to have you on board. As a
              rider, you’ll enjoy the freedom to move on your terms, with access
              to reliable rides and a platform that puts you in control. As a
              driver, you’ll join a community of professionals, earn competitive
              rates, and enjoy the flexibility to work when and where it suits
              you.
              <br />
              <br />
              Applying is quick and easy. Riders, sign up to access our network
              of trusted drivers and seamless booking options. Drivers, complete
              a simple application, get verified, and start earning right away.
              At Sprinterwave, we’re all about creating opportunities and making
              every journey better. Ready to get started? Apply today and let’s
              move forward together!
            </p>

            <div className={classes.apply_btn}>
              <button onClick={toggleLogin}>Start Riding</button>
            </div>
          </div>
        </div>

        <div className={classes.left_image}>
          <img
            src="https://images.pexels.com/photos/1552224/pexels-photo-1552224.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
          />
        </div>

        {showForm && (
          <div className={classes.rider_login_wrapper}>
            <div className={classes.login_wrapper}>
              <div>
                <div className={classes.row}>
                  <h1>Customer login</h1>
                  <FaTimes size={25} onClick={() => setShowForn(false)} />
                </div>{" "}
                <p>
                  Please sign in using your regular customer account here. We
                  will then start setting up your rider account. Please be aware
                  that you must be an existing customer to qualify for rider
                  status.{" "}
                </p>
                <form action="" onSubmit={onSubmitHandler}>
                  <input
                    type="email"
                    placeholder="Enter your email address"
                    onChange={onChangeHandler}
                    name={"email"}
                    required
                  />
                  <br />
                  <input
                    type="password"
                    placeholder="Enter password"
                    name={"password"}
                    onChange={onChangeHandler}
                    required
                  />
                  <br />
                  <input type="submit" value="Login" />
                </form>{" "}
              </div>
            </div>
          </div>
        )}

        {showVerifyForm && (
          <div className={classes.rider_login_wrapper}>
            <div className={classes.login_wrapper}>
              <div>
                <div className={classes.row}>
                  <h1>Verify Account</h1>
                  <FaTimes size={25} onClick={toggleVerificationForm} />
                </div>{" "}
                <p>Please check yoru email a verification code was sent.</p>
                <form action="" onSubmit={onVerifyhandler}>
                  <input
                    type="text"
                    placeholder="Enter code"
                    value={code}
                    onChange={(el: React.ChangeEvent<HTMLInputElement>) =>
                      setCode(el.target.value)
                    }
                    name={"code"}
                    required
                  />

                  <br />
                  <p
                    className={classes.request_code_style}
                    onClick={onRequestCodeHandler}
                  >
                    Didn't get the code?
                  </p>
                  <input type="submit" value="Verify" />
                </form>{" "}
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default RiderSection1;
