import React from "react";
import { BsWallet } from "react-icons/bs";
import { MdEvent } from "react-icons/md";
import { IoMdGlobe } from "react-icons/io";

import classes from "./ColsStyles.module.css";
function Col1() {
  return (
    <div className={classes.main}>
      <div className={classes.data}>
        <h1>Our aim is to provide an incomparable experience</h1>

        <p>
        At Sprinterwave, we aim to make life easier. We help you book rides, pay bills, and plan events—all in one place. Our goal is simple: to connect you faster and make every day smoother
        </p>

        <div className={classes.flex_btm_box}>
          <div className={classes.box_1}>
            <i>
              <IoMdGlobe />
            </i>
            <h3>Anytime Booking</h3>
            <p>
            Book rides, dispatch services, and events whenever you need—day or night. Your plans, your time, our priority.


            </p>
          </div>

          <div className={classes.box_1}>
            <i>
              <BsWallet />
            </i>
            <h3>Anytime Transaction</h3>
            <p>
            Pay bills, shop, and handle transactions anytime, anywhere. Fast, secure, and hassle-free—because your time matters."


            </p>
          </div>
        </div>
      </div>
      <div className={classes.image_wrapper}>
       
        <img
          src="/images/map.jpeg"
          alt=""
        />
        <img
          src="/images/receipt.jpeg"
          alt=""
        />
   
      </div>
    </div>
  );
}

export default Col1;
