import React, { useRef } from "react";
import { MdClose } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import classes from "./MobileNavStyles.module.css";

interface Props {
  onCLosehandler: () => void;
}
const MobileNav: React.FC<Props> = ({ onCLosehandler }) => {
  const navigate = useNavigate();
  const closeButtonRef = useRef<HTMLDivElement>(null);

  const onNavigateHandler = (link: string) => {
    navigate(link);
  };
  return (
    <div className={classes.container}>
      <div
        className={classes.close_container}
        onClick={onCLosehandler}
        ref={closeButtonRef}
      >
        <MdClose />
      </div>

      <div className={classes.wrapper}>
        <ul>
          <li>
            <span
              className={window.location.pathname === "/" ? classes.active : ""}
            >
              <Link to={"/"}>Home</Link>
            </span>
          </li>

          <li>
            <span
              className={
                window.location.pathname === "/about" ? classes.active : ""
              }
            >
              <Link to={"/about"}>About</Link>
            </span>
          </li>

          {/* <li>
            <span
              className={
                window.location.pathname === "/blog" ? classes.active : ""
              }
            >
              <Link to={"/blog"}>Blog</Link>
            </span>
          </li> */}

          <li>
            <span
              className={
                window.location.pathname === "/privacy-policy"
                  ? classes.active
                  : ""
              }
            >
              <Link to={"/privacy-policy"}>Privacy Policy</Link>
            </span>
          </li>
          <li>
            <span
              className={
                window.location.pathname === "/terms-and-conditions"
                  ? classes.active
                  : ""
              }
            >
              <Link to={"/terms-and-conditions"}>Terms and Conditions</Link>
            </span>
          </li>
          <li>
            <span
              className={
                window.location.pathname === "/contact" ? classes.active : ""
              }
            >
              <Link to={"/contact"}>Contact</Link>
            </span>
          </li>

          {/* <li onClick={() => onNavigateHandler("/")} ><Link </li>
          <li onClick={() => onNavigateHandler("/about")} >About</li>
          <li onClick={() => onNavigateHandler("/blog")} >Blog</li>
          <li onClick={() => onNavigateHandler("/contact")} >Contact Us</li> */}
        </ul>
      </div>
    </div>
  );
};

export default MobileNav;
